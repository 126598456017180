<template>
    <div :height="cardHeight" class="leaderboard">
        <div class="deep-purple lighten-5 pa-4 pb-1">
            <div v-if="subtitle" class="overline">{{subtitle}}</div>
            <h4>{{title}}
                <v-btn icon @click="resetData" v-if="allowRefresh">
                    <v-icon small>fas fa-sync-alt</v-icon>
                </v-btn>
            </h4>
        </div>
        <v-row :class="$vuetify.breakpoint.xs && 'flex-column-reverse'">
            <v-col cols="12" sm="8" md="9" lg="10">
                <template v-if="!loading">
                    <slot name="append-top"></slot>
                    <QExpandableDataTable class="q-drilldown-agent-data-table__content" v-if="theData && theData.length > 0" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="searchComputed" :headers="headers" :visibleHeaders="visibleHeaders" :items="theData" item-key="AgentID" :class="table_class" :loading="loading" v-on:click:row="drillDownAgent" color="deep-purple">
                        <template v-slot:item.Rank="{ item }">
                            <span class="nowrap"><strong>#{{ item.RankByLeadershipLevel ? item.RankByLeadershipLevel : item.Rank }}</strong></span>
                        </template>
                        <template v-slot:item.AgentName="{ item }">
                            <span @click.stop.prevent>
                                <router-link :to="'/agents/' + item.AgentCode">{{ getAgentPreferredName(item) }}</router-link>
                            </span>
                        </template>
                        <template v-slot:item.UplineAgentName="{ item }">
                            <span>
                                <router-link :to="'/agents/' + item.UplineAgentCode">{{ item.UplineAgentName }}</router-link>
                            </span>
                        </template>
                        <template v-slot:item.BaseshopOwnerAgentName="{ item }">
                            <span>
                                <router-link :to="'/agents/' + item.BaseshopOwnerAgentCode">{{ item.BaseshopOwnerAgentName }}</router-link>
                            </span>
                        </template>
                        <template v-slot:item.Apps="{ item }">
                            <span :class="item.AgentCode == user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ Math.round(item.Apps) }}</span>
                        </template>
                        <template v-slot:item.URL="{ item }">
                            <router-link :to="item.URL">{{ item.URL }}</router-link>
                        </template>
                        <template v-slot:item.LeadershipLevelStartDate="{ item }">
                            <span>{{formatReadableDate(item.LeadershipLevelStartDate)}}</span>
                        </template>
                        <template v-slot:item.BirthDate="{ item }">
                            <span>{{formatReadableDate(item.BirthDate)}}</span>
                        </template>
                        <template v-slot:item.Apv="{ item }">
                            <v-icon left v-if="item.IsAgencyOwner && item.AgentCode !=agentCode && allowDrilldown">fas fa-angle-double-down</v-icon>
                            <span class="nowrap">{{ formatCurrency(item.Apv) }}</span>
                        </template>
						<template v-slot:item.SavedPremium="{ item }">
							<span class="nowrap">{{ formatLongCurrency(item.SavedPremium) }}</span>
						</template>
                        <template v-slot:item.Leads="{ item }">
                            <span :class="item.AgentCode == user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ Math.round(item.Leads) }}</span>
                        </template>
                        <template v-slot:item.CloseRatio="{ item }">
                            <v-icon left v-if="item.IsAgencyOwner && item.AgentCode !=agentCode && allowDrilldown">fas fa-angle-double-down</v-icon>
                            <span :class="item.AgentCode == user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ formatPercentage(item.CloseRatio) }}</span>
                        </template>
                        <template v-slot:item.Amount="{ item }">
                            <v-icon left v-if="item.IsAgencyOwner && item.AgentCode != agentCode && allowDrilldown">fas fa-angle-double-down</v-icon>
                            <span :class="item.AgentCode == user.AgentCode ? 'agent-strong nowrap' : 'nowrap'">{{ item.Amount }}</span>
                        </template>
                        <template slot="body.append">
                            <tr>
                                <th class="title text-right" v-for="(header,ix) in visibleHeaders" :key="ix">
                                    <span v-if="header.value == 'CloseRatio'">
                                        {{ formatPercentage(sumField('Apps') / sumField('Leads')) }}
                                    </span>
                                    <span v-if="header.value == 'LastSignIn'">
                                        Total: {{ theData.length }}
                                    </span>
                                    <span v-if="header.value == 'ContractStartDt'">
                                        Total: {{ theData.length }}
                                    </span>
                                    <span v-else-if="sumFields.indexOf(header.value) > -1">
                                        <span v-if="header.value == 'Apv'"><strong>{{ formatCurrency(sumField(header.value))}}</strong></span>
                                        <span v-else><strong>{{ sumField(header.value)}}</strong></span>
                                    </span>
                                </th>
                            </tr>
                        </template>
                    </QExpandableDataTable>
                    <slot v-else name="no-data" />
                    <v-row v-if="!loading">
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary" @click="exportResults" :disabled="!disableExportResults">
                                <v-icon left>fas fa-file-export</v-icon> Export Results
                            </v-btn>
                        </v-col>
                    </v-row>
                    <slot name="append-bottom"></slot>
                </template>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="2">
                <v-text-field
					v-model="searchComputed"
					label="Search"
					append-icon="icon-q-search"
					clearable
					single-line hide-details
					class="mb-4"
				/>
                <slot name="filters" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { Parser } from 'json2csv'
import QExpandableDataTable from '../QExpandableDataTable.vue';

export default {
    name: "QDrilldownAgentDataTable",
    props: {
        'title': {
            type: String,
            default: 'Leaderboard'
        },
        'subtitle': {
            type: String,
            default: null
        },
        'rows': {
            type: Number,
            default: 20
        },
        'moreLink': {
            type: String,
            default: null
        },
        'theData': {
            type: Array,
            default: function() {
                return []
            }
        },
        'headers': {
            type: Array,
            default: function() {
                return []
            }
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        },
        'loading': {
            type: Boolean,
            default: false
        },
        'allowDrilldown': {
            type: Boolean,
            default: true
        },
        'timePeriod': {
            type: String,
            default: "month"
        },
        'agentCode': {
            type: String,
            default: ""
        },
        'filters': {
            type: Object,
            default: function() {
                return {}
            }
        },
        'sortBy': {
            type: String,
            default: null
        },
        'sortDesc': {
            type: Boolean,
            default: null
        },
        'exportFields': {
            type: Array,
            default: function() {
                return []
            }
        },
        'allowRefresh': {
            type: Boolean,
            default: true
        },
        search: { type: String, default: undefined }
    },
    mounted: function() {
    },
    data: function() { // data internal to component (not available outside)
        return {
            selected: [],
            innerSearch: null,
            sumFields: [
                'Apv',
                'Apps',
                'Leads',
                'Amount',
                'ALeadsAmount',
                'BonusLeadsAmount',
                'OtherLeadsAmount'
            ],
        }
    },
    computed: {
        searchComputed: {
            get() {
				if (this.$props.search === undefined) { return this.innerSearch }
                return this.search;
            },
            set(value) {
				this.innerSearch = value
                this.$emit('update:search', value);
            }
        },
        cardHeight: function() {
            if (this.$vuetify.breakpoint.xs) {
                return null
            }
            return 257 + (this.rows * 56) + "px"
        },
        table_class: function() {
            if (this.allowDrilldown) {
                return 'elevation-1 leaderboard-datatable pointer'
            }
            return 'elevation-1 leaderboard-datatable'
        },
        visibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return this.headers.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return this.headers.slice(0, 3)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.headers.slice(0, 4)
            }

            return this.headers
        },
		disableExportResults() {
			if (this.theData) {
				return this.theData.length > 0 ? true : false
			} else {
				return false
			}
		}
    },
    methods: {
        row_classes: function(item) {
            return 'test'
        },
        exportResults: function() {
            //these fields are being used???
            //exporting exactly what is in the API returned data.
            var fields = this.exportFields; //this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.theData);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title + " " + this.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Agent Drilldown Export", { "leaderboard": this.title })
            } catch (err) {
                console.error(err);
            }

        },
        getAgentPreferredName: function(item) {
            if (typeof item.PreferredName != 'undefined' && item.PreferredName != null && item.PreferredName != '') {
                return item.PreferredName
            }
            if (typeof item.PreferredAgentName != 'undefined' && item.PreferredAgentName != null && item.PreferredAgentName != '') {
                return item.PreferredAgentName
            }
            return item.AgentName

        },
        resetData: function() {
            this.$emit('reload');
        },
        drillDownAgent: function(item) {
            if (!item.IsAgencyOwner || !this.allowDrilldown) {
                return
            }
            var params = {
                agent_code: item.AgentCode,
            }
            this.$router.push({
                name: this.$route.name,
                params: params,
                query: {
                    endDate: this.filters.endDate,
                    startDate: this.filters.startDate,
                    timePeriod: this.filters.timePeriod,
                    statType: 'Baseshop' // force baseshop on the second layer of drill downs.
                }
            });
        },
        sumField(key) {
            return this.theData.reduce((a, b) => a + (b[key] || 0), 0)
        }
    },
    components: {
        QExpandableDataTable,
    }
}
</script>
<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

.agent-strong {
    font-weight: 900;
}

.rank-header {
    white-space: nowrap;
}
</style>
